<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <Main-top
      v-if="!categoryLoading"
      class="cloud-main-top"
      :title="$t('page.cloud')"
      :filterMode="true"
      :categoryData="categoryData"
      :routeCategory="routeCategory"
      :routeTime="routeTime"
    ></Main-top>
    <div class="filterResults-index-bg">
      <EventBlock v-if="hasFilter" />
      <template v-else>
        <EventBlock
          :title="$t('cloud.future-event')"
          :type="'future'"
          class="mb-10"
        />
        <EventBlock :title="$t('cloud.past-event')" :type="'past'" />
      </template>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import indexMixins from "@/components/page/index.js"
import EventBlock from '@/components/cloud/eventBlock.vue'
export default {
  mixins: [indexMixins],
  components: {
    EventBlock,
  },
  data: ()=>({
    count: 0,
  }),
  computed: {
    breadcrumb() {
      return [
        {
          title: this.$t('page.cloud'),
          name: 'cloud-index'
        }
      ]
    },
    categoryIndexApi() {
      return this.$api.cloud.public.categoryIndex
    },
    seoTitle() {
      return this.$store.getters['base/cloud_seo_title']
    },
    seoDescription() {
      return this.$store.getters['base/cloud_seo_description']
    },
    seoKeywords() {
      return this.$store.getters['base/cloud_seo_keywords']
    },
    seoCanonicalTag() {
      return this.$store.getters['base/cloud_seo_canonical_tag']
    },
    application() {
      return this.$store.getters['base/application']
    },
    siteId() {
      return this.$store.getters["base/siteId"];
    },
  },
  watch: {
    count() {
      if(this.count == 2) {
        this.setupMeta()
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    afterIndexFetch() {
      this.count += 1
    },
    async init () {
      try {
        if (this.application.site_data.status == 0 ) {
          if ("/"+this.siteId+"/develop" === this.$route.path) {
            return;
          }
          this.$router.replace({ name: "develop" });
          await this.$helper.delay(0.5);
          return;
        }
        
      } catch (err) {
        console.error(err);
        if (err && err.status == "404") {
          if (!err.data) {
            this.$router.replace({ name: "404" });
            return;
          }
          if (err.data.message == "failed") {
            this.$router.replace({ name: "develop" });
            return;
          }
          this.$router.replace({ name: "404" });
        }
      } finally {
        this.$store.dispatch("base/loading", false);
        this.$store.commit(`base/setMainApiLoadDone`, true);
      }
    }
  },
};

</script>

<style lang="sass" type="text/sass">
.filterResults-index-bg
  background-image: url('~@/assets/img/bg/filterResultBg.png')
  background-position: center top
  -webkit-backdrop-filter: blur(30px)
</style>